<template>
  <div
    class="
      relative
      flex flex-col
      items-start
      justify-between
      p-4
      space-y-2
      bg-white
      rounded-lg
    "
  >
    <div
      class="
        absolute
        grid
        w-6
        h-6
        rounded-full
        -top-2
        -left-2
        bg-brand-primary
        place-items-center
      "
    >
      <icon-tip class="w-4 h-4 fill-current text-brand-primary" />
    </div>

    <div>
      <q class="text-brand-dark">{{ tip.title }}</q>
      <p
        v-if="tip.description"
        class="mt-4 text-sm text-brand-dark"
        v-html="tip.description"
      ></p>
    </div>

    <div>
      <t-button @click="addToPersonalChecklist" variant="smallborder">
        <span>{{ $t("articles.tips.add_to_personal") }}</span>
      </t-button>
    </div>
  </div>
</template>

<script>
import IconTip from "assets/icons/tip.svg";
import TodoRepository from "repositories/TodoRepository";
import { createAlert } from "../../utils/constants";

export default {
  name: "Tip",

  components: {
    IconTip,
  },

  props: {
    tip: {
      type: Object,
    },
  },

  methods: {
    async addToPersonalChecklist() {
      try {
        const response = await TodoRepository.create({
          description: this.tip.title,
        });

        if (response.status === 201) {
          this.$store.commit(
            "alerts/ADD_ALERT",
            createAlert(this.$t("alerts.added_tip"), "SUCCESS")
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
