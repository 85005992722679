<template>
  <div class="relative">
    <portal to="topbar-left">
      <div
        @click="$router.go(-1)"
        class="font-bold"
        :class="{
          'text-white': isCheckedIn,
          'text-brand-dark': !isCheckedIn,
        }"
      >
        <icon-back class="h-6 w-6" />
      </div>
    </portal>

    <div class="mx-auto flex min-h-full max-w-screen-sm flex-col space-y-4">
      <div
        v-if="loading"
        class="flex flex-col items-center space-y-4 p-4 text-center"
      >
        <icon-loading class="h-10 w-10 animate-spin text-brand-lightblue" />
        <p class="text-xs font-semibold">{{ $t("action.loading") }}</p>
      </div>
      <div v-else-if="article && article.id" class="relative mx-auto w-full">
        <div class="space-y-4 p-4">
          <h1 class="text-xl font-semibold">{{ article.title }}</h1>
          <div
            class="relative mx-auto mt-6 min-h-full max-w-screen-sm overflow-hidden break-words rounded-lg bg-white text-sm leading-normal"
            :class="{
              'pt-12': !article.image,
            }"
          >
            <div
              class="absolute top-0 right-0 z-49 flex items-stretch justify-between rounded-bl-md"
              :class="{
                'bg-brand-darkblue': !article.image,
                'bg-white bg-opacity-50 backdrop-blur-sm backdrop-filter':
                  !!article.image,
              }"
            >
              <template v-if="showTips">
                <button
                  type="button"
                  @click="goto('tip-section')"
                  class="focus:outline-none z-75 flex items-center justify-center rounded-bl bg-opacity-75 p-4 text-white ring-white focus:bg-black focus:ring-2"
                >
                  <icon-tip class="h-5 w-5 fill-current stroke-current" />
                  <span class="pl-2 font-semibold">{{
                    article.tips.length
                  }}</span>
                </button>
              </template>

              <button
                v-if="isAuthenticated"
                type="button"
                @click="updateBookmark"
                class="focus:outline-none flex items-center justify-center rounded-bl bg-opacity-75 p-4 text-white ring-white focus:bg-black focus:ring-2"
              >
                <icon-bookmark v-if="bookmarked" class="h-5 w-5" />
                <icon-bookmark-outline v-else class="h-5 w-5" />
              </button>
            </div>
            <div
              class="aspect-w-16 aspect-h-9 h-48 overflow-hidden shadow-lg sm:relative sm:h-64 sm:rounded-lg"
              v-if="article.image"
            >
              <img
                :src="article.image"
                :alt="article.title"
                class="h-full w-full object-cover"
              />
            </div>
            <div class="p-4">
              <div
                class="prose max-w-none break-words"
                v-html="article.content"
              ></div>
              <p class="mt-4 text-xs text-gray-500" v-if="isChemsexStory">
                {{ $t("articles.chemsex-cta") }}
                <a
                  href="mailto:hello@budd.be"
                  class="text-brand-primary underline"
                  >hello@budd.be</a
                >
              </p>
            </div>
          </div>

          <template v-if="article.meta && article.meta.youtube_video_id">
            <div class="aspect-w-16 aspect-h-9 relative w-full">
              <iframe
                type="text/html"
                width="720"
                height="405"
                class="h-full w-full"
                :src="`https://www.youtube-nocookie.com/embed/${article.meta.youtube_video_id}?enablejsapi=1&hl=${this.$i18n.locale}&modestbranding=1&playsinline=1&color=white`"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </template>

          <template v-if="showTips">
            <h3 class="text-xl font-bold" ref="tip-section">
              {{ $t("articles.tips.title") }}
            </h3>
            <article-tip
              v-for="tip in article.tips"
              :tip="tip"
              :key="tip.uuid"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Repository from "repositories/RepositoryFactory";
import IconBack from "assets/icons/back.svg";
import IconLoading from "assets/icons/loading.svg";
import IconBookmark from "assets/icons/bookmark.svg";
import IconTip from "assets/icons/tip.svg";
import IconBookmarkOutline from "assets/icons/bookmark-outline.svg";
import ArticleTip from "../../components/Articles/Tip";
import { mapGetters } from "vuex";

const ArticleRepository = Repository.get("articles");

export default {
  name: "article-single",
  components: {
    IconBack,
    IconLoading,
    IconBookmark,
    IconBookmarkOutline,
    ArticleTip,
    IconTip,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      bookmarked: false,
      loading: true,
      article: {
        id: "",
        title: "",
        slug: "",
        excerpt: "",
        category: "",
        category_id: null,
        content: "",
        image: "",
        bookmarked: false,
      },
    };
  },

  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    async updateBookmark() {
      this.bookmarked = !this.bookmarked;
      const { data } = await ArticleRepository.bookmarkArticle(
        this.article.id,
        !this.article.bookmarked
      );

      if (data.success)
        ArticleRepository.get(this.id).then(({ data }) => {
          this.$store.dispatch("getArticles", { language: this.$i18n.locale });
          this.article = data.data;
          this.bookmarked = data.data.bookmarked;
        });
    },

    getArticle: function (id) {
      this.loading = true;
      ArticleRepository.get(id)
        .then(({ data }) => {
          this.article = data.data;
          this.bookmarked = data.data.bookmarked;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted: function () {
    if (!this.id) {
      return;
    }
    this.getArticle(this.id);
  },

  computed: {
    ...mapGetters(["isCheckedIn", "isAuthenticated"]),

    isChemsexStory() {
      return (
        this.article.category &&
        this.article.category.toLowerCase().includes("chemsex")
      );
    },

    showTips() {
      return (
        this.article.tips && this.article.tips.length && this.isAuthenticated
      );
    },
  },

  metaInfo() {
    return {
      title: `${
        this.article ? this.article.title : "Loading..."
      } - ${this.$i18n.t("pages.article")}`,
      meta: [{ name: "description", content: this.article.excerpt || "" }],
    };
  },
};
</script>
